import React from "react"

import "../css/__forms.scss"

const ContactForm = (props) => {

    return (

        <div>

            <form method="POST" action="https://api.web3forms.com/submit" name="contact-form" className="contact-form" id="contact-form">

                <input type="hidden" name="access_key" value="90f162d7-8055-4e5f-b98e-bead622e937a" />

                {props.children}

                <input required type="text" placeholder="Name" name="name"></input>

                <input required type="email" placeholder="Email" name="email"></input>

                <textarea required placeholder="Message" name="message" rows="10"></textarea>

                <div>
                    <input type="submit" className="button"></input>
                </div>


            </form>

        </div>

    )

}

export default ContactForm
