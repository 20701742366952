import React from "react"
import { Link } from "gatsby"

import "../css/__navbar.scss"

import logo from "../images/logo.png"

const Header = () => {
	return (

		<nav className="navBar">

            <div className = "container nav-grid">

                <Link to="/" className="logo"><img src={logo} alt="Logo" /></Link>

                <ul className = "links">

                    <li><a className="hover" href="#contact">Contact</a></li>

                </ul>

            </div>

        </nav>

	)
}

export default Header