import React from "react"
import Layout from "../components/layout"
import Portfolio from "../components/portfolio"
import ContactForm from "../components/contactform"

import "../css/index.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Socials from "../components/socials";

const indexPage = () => {

	return (

		<Layout title="Home">

			<section className="hero">

				<FontAwesomeIcon size="lg" className="down-arrow" icon="arrow-down" />

				<div className="container animate hidden">

					<h1>Hi, I'm Nick</h1>

					<h3>I'm a freelance designer and developer</h3>

					<div>
						<h4>
							<a href="#contact" className="button">
								Contact Me
							</a>
						</h4>
					</div>

					<Socials />

					<div class="discord">

						<iframe src="https://discordapp.com/widget?id=491759036757442571&theme=dark" width="350" height="300" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>

					</div>

				</div>

			</section>

			<Portfolio />

			<section class="padded light stream">

				<h1 className="header">

					<a href="https://mixer.com/nickonamy" target="_blank" rel="noopener noreferrer">

						Watch My Stream

					</a>

				</h1>

				<div class="container">


					<div className="iframe-container">

						<iframe allowfullscreen="true" src="https://mixer.com/embed/player/Nickonamy?disableLowLatency=1"> </iframe>

					</div>

					<div className="iframe-container">

						<iframe allowfullscreen="true" src="https://mixer.com/embed/chat/Nickonamy"> </iframe>

					</div>

				</div>

			</section>

			<section className="padded dark contact" id="contact">

				<div className="animate hidden">

					<h1 className="header">Contact Me</h1>

					<div className="container">

						<ContactForm />

					</div>

					<div className="container hidden no-pointer thank-you" id="thank-you">

						<h6 align="center">Thanks for reaching out, you should hear back from me within 48 hours!</h6>

					</div>

				</div>
				<a className="hidden" rel="me" href="https://mastodon.social/@nickuous">Mastodon</a>

			</section>

			<a href="#" className="button button--inverted button--to-top"><FontAwesomeIcon icon="arrow-up" /></a>

		</Layout>

	)

}

export default indexPage
