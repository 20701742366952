import React from "react"
import { graphql, useStaticQuery} from "gatsby"

import "../css/__portfolio.scss"

const Portfolio = () => {

	const data = useStaticQuery(graphql`
		query{
			allBehanceProjects{
				edges{
					node{
						short_url
						name
						description
						cover{
							path
							src
						}
					}
				}
			}
		}`
	)
	
	return (

		<div className="portfolio">

			{data.allBehanceProjects.edges.map((edge) => {

				return (
					
					<div>

						<a className="portfolio__item" href={`${edge.node.short_url}`} target="_blank" rel="noopener noreferrer">
						
							<div className="portfolio__item__text">

								<h2 className="portfolio__item__name">{edge.node.name}</h2>
								<h3 className="portfolio__item__description">{edge.node.description}</h3>

							</div>

							<div className="portfolio__item__background"></div>

							<img src={`${edge.node.cover.src}`} alt={`${edge.node.name}`} />
						
						</a>

					</div>

				)

			})}

		</div>

	)
	
}

export default Portfolio