import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Socials = () => {

	return (

		<div className="socials">
			<a className="hover" href="https://twitter.com/nickuous" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "twitter"]} size="lg" /></a>
			<a className="hover" href="https://twitch.tv/nickuous" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "twitch"]} size="lg" /></a>
			<a className="hover" href="https://instagram.com/nickuous" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "instagram"]} size="lg" /></a>
			<a className="hover" href="https://youtube.com/nickuous" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "youtube"]} size="lg" /></a>
			<a className="hover" href="https://github.com/nickuous" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "github"]} size="lg" /></a>
			<a className="hover" href="https://behance.net/nickconway" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={["fab", "behance"]} size="lg" /></a>
		</div>

	)
}

export default Socials
