import React from "react"
import Helmet from "react-helmet"
import {useStaticQuery, withPrefix, graphql} from "gatsby"

import Header from "./navbar"
import Footer from "./footer"

import "../css/__layout.scss"
import '@fortawesome/fontawesome-svg-core/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faKey, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram, faYoutube, faTwitch, faGithub, faBehance } from "@fortawesome/free-brands-svg-icons"

var faMixer = {
	prefix: 'fab',
	iconName: 'mixer',
	icon: [512, 453.31, [], 'e001', 'M121,84.26a43.64,43.64,0,0,0-64.44-6.12C39.79,93.59,38.47,119.67,52,138.05l87.6,117.42L51.51,374C37.94,392.33,39,418.41,56,433.86a43.64,43.64,0,0,0,64.44-6.12l123-165.35a11.59,11.59,0,0,0,0-13.58Zm270,0a43.64,43.64,0,0,1,64.44-6.12c16.78,15.45,18.1,41.53,4.53,59.91l-87.6,117.42L460.49,374c13.57,18.38,12.51,44.46-4.53,59.91a43.64,43.64,0,0,1-64.44-6.12L268.78,262.12a11.59,11.59,0,0,1,0-13.58Z']
  }

library.add(faEnvelope, faKey, faArrowUp, faTwitter, faInstagram, faYoutube, faTwitch, faGithub, faBehance, faMixer, faArrowDown);

const Layout = (props) => {

	const data = useStaticQuery(graphql`
		query{
			site{
				siteMetadata{
					title
				}
			}
		}
	`)
	return (

		<div className="site">

			<Helmet title={`${props.title} | ${data.site.siteMetadata.title}`}>

				<script src={withPrefix("main.js")} type="text/javascript" />

			</Helmet>

			<Header/>

			{props.children}

			<Footer/>

		</div>

	)
	
}

export default Layout