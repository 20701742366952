import React from "react"
import Socials from "../components/socials"

import "../css/__footer.scss"

const Footer = () => {
	return (

		<footer>

            <div className="container">

			    <Socials/>

            </div>

		</footer>

	)
}

export default Footer